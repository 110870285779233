.logo-125{
  width: 125px;
  height: auto;
}
.logo-200{
  width: 200px;
}
.compact-logo{
  max-width: none;
  transition: .3s ease;
  height: 40px;
  /* margin-left: -10px; */
}
.mt--10{
  margin-top: -10px;
}
.mt-100{
  margin-top: 100px;
}
.mb-100{
  margin-bottom: 100px;
}
.pb-75{
  padding-bottom: 75px;
}
.fz-50{
  font-size: 50px;
}
.stat-card{
  border: 3px solid #6ac2fd;
  background: rgba(255,255,255,0.8);
}
.w50{
  width: 50px;
}
.text-right{
  text-align: right;
  float: right;
}

.align-center{
  align-items: center;
}

.form-control:focus {
  border-color: #21A1F6 !important;
  outline: none !important;
  box-shadow: 0 0 5px #21A1F6;
}
.ml10{
  margin-left: 10px;
}
.ml20{
  margin-left: 20px;
}
.ml5{
  margin-left: 5px;
}
.mt32{
  margin-top: 32px;
}
.pointer{
  cursor: pointer;
}
.pr0{
  padding-right: 0px;
}
.pt-32{
  padding-top: 32px;
}
.chatbot-input{
  height: 44px !important;
  min-height: 44px !important;
  resize: none;
  border: none;
  padding: 0px;
  width: 100%;
  /* border: 1px solid black; */
}
.chatbot-input:focus, .chatbot-input:active {
  outline: none;
}
.chatbot-send{
  height: 44px;
}
.chat-container{
  position: absolute;
  bottom: 0;
}
.chat-box{
  padding: 20px 0px;
  background: #fcfcfc;
  border-radius: 20px;
  margin-bottom: 20px;
}
.bot-bubble{
  background: white;
  box-shadow: -0.5px 0.5px 19px 0.5px rgba(32, 160, 245, 0.2);
  border: 1px solid #92CAFF;
}
.chat-bottom{
  position: fixed;
  background: white;
  bottom: 10px;
  width: calc(100% - 390px);
  border-radius: 20px;
  margin-left: 60px;
  padding: 20px;
  box-shadow: 0.5px 0.5px 10px 2.5px #ececec;
}
.chatbot-mb{
  margin-bottom: 100px;
}
.message{
  white-space: pre-wrap;
  font-size: 14px;
  padding: 40px;
  color: black;
}
.cut-price{
  font-size: 20px;
}
.p0{
  padding: 0px;
}
.card-element{
  border: 1px solid #e4e4e4;
  padding: 10px;
  border-radius: 5px;
  color: #43476b;
}
.profile-pic{
  height: 100px;
  width: 100px !important;
  width: auto;
  border-radius: 50px;
  object-fit: cover;
}
.chat-avatar{
  height: 30px;
  width: 30px !important;
  width: auto;
  border-radius: 15px;
  object-fit: cover;
}
.d-none{
  display: none;
}

.drag-row{
  padding: 20px 0px;
  border-bottom: 1px solid rgb(226, 226, 226);
}

.c-primary{
  color: #1F8DF4;
}

.c-primary:focus, .c-primary:active {
  border: none;
}

.c-primary:hover{
  color: #146fc4;
}
.chat-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 40px;
  padding-top: 10px;
}
.chat-user{
  margin-left: 10px;
  color: #1E93DF;
}
.chat-actions{
  margin-right: 40px;
}
.chat-divider{
  border: 0.5px solid #e6e6e6;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 0px;
}
.color-white{
  color: white;
}
.color-primary{
  color: #1F8DF4;
}
.border-primary{
  border-color: #1F8DF4 !important;
}
.nk-menu-text:hover{
  color: #c5c5c5;
}

.cursor {
  display: inline-block;
  opacity: 1;
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@media all and (max-width: 1000px) {
  .chat-bottom{
    width: calc(100% - 70px);
    margin-left: 30px;
  }
}